<template>
    <a
        :href="href"
        :title="title"
        class="mention-link"
        :class="{ 'read': mention.read_at }"
        @click.prevent="readAndNavigate"
    >
        <div class="flex jc-between" :class="headerClass">
            <div class="uppercase">{{ mention.reference.reference_title }}</div>
            <div class="muted">{{ mention.updated_at }}</div>
        </div>

        <div v-html="html" :class="bodyClass"></div>
    </a>
</template>

<script>
    import { mapActions } from 'vuex';
    import Mentions from '../Users/repositories/MentionsRepository.js';

    export default {
        name: 'MentionLink',

        props: {
            mention: {
                type: Object,
                required: true,
            },

            referenceClass: {
                type: String,
                default: null,
            },

            bodyClass: {
                type: String,
                default: null,
            },
        },

        computed: {
            headerClass() {
                return !this.mention.read_at
                    ? this.referenceClass + ' bold'
                    : this.referenceClass;
            },

            href() {
                return '/app' + this.mention.url;
            },

            title() {
                return this.mention.preview.replace(/\{\{(.*)}}/, '$1');
            },

            html() {
                return `<span class="muted">(${this.mention.creator.first_name} ${this.mention.creator.last_name[0]}.)</span> ` +
                    this.mention.preview.replace(/\{\{(.*)}}/, '<span class="mention">$1</span>');
            }
        },

        methods: {
            ...mapActions([
                'updateMention',
            ]),

            readAndNavigate() {
                this.readMention();
                this.$router.push(this.mention.url);
                this.$emit('navigate');
            },

            readMention() {
                if ( Boolean(this.mention.read_at) ) {
                    return;
                }

                const { mentionable_type, mentionable_id } = this.mention;
                Mentions.params({ mentionable_type, mentionable_id })
                    .update(`${this.mention.mention_id}/read`, { read: true })
                    .then(({data}) => {
                        this.updateMention(data.data);
                    });
            }
        },
    };
</script>
