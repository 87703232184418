<template>
    <ks-top-nav :fixed="true" :has-sidebar="true">
        <div class="brand">
            <img src="/images/fossa_logo_full.png" alt="Work Fossa" />
        </div>

        <transition name="slide-down">
            <span
                v-if="isMobile && unreadMentionsCount > 0"
                class="unread-count absolute flex jc-center align-center rounded-full bg-red-600 text-white text-xs shadow"
                style="top:1rem;right:1rem;"
                title="Notifications"
            >
                {{ unreadMentionsCount }}
            </span>
        </transition>

        <div class="collapse">
            <ul class="dropdown right">
                <li>
                    <router-link :to="{name: 'user-directory'}">Directory</router-link>
                </li>
                <li v-if="$userCan('reference_documents_view')">
                    <router-link :to="{name: 'reference-documents'}">References</router-link>
                </li>
                <li v-if="$userCanGroup('admin', 'certification')">
                    <router-link :to="{ name: 'admin-dashboard' }" >Admin</router-link>
                </li>
                <li v-if="is_impersonating">
                    <a
                        href="#"
                        style="background:#7746ec;height:40px;border-radius:5px;margin:0 0.25rem;"
                        @click.prevent="stopImpersonating"
                        class="unimpersonate-link"
                    >
                        <vault-icon :icon="userIcon" style="color:#fff;"></vault-icon>
                        <span class="text-white pl-1.5 tab-hide-up">Stop Impersonating</span>
                    </a>
                </li>
                <li v-if="canViewTasks" :class="activeLinkClasses('task-dashboard')">
                    <component
                        :is="$route.name === 'task-dashboard' ? 'a' : 'router-link'"
                        :to="{name: 'task-dashboard'}"
                        style="background: transparent;color: inherit"
                    >
                        <vault-icon :icon="task_icon"></vault-icon>
                    </component>
                </li>

                <li>
                    <a href="#" class="notifications-dropdown-toggle relative" @click.prevent.stop="clickNotifications">
                        <template v-if="isMobile">
                            Notifications
                        </template>
                        <template v-else>
                            <vault-icon :icon="notification_icon" :class="{ 'muted': unreadMentionsCount === 0 }"></vault-icon>
                        </template>
                        <transition name="slide-down">
                            <span
                                v-if="unreadMentionsCount > 0"
                                class="unread-count ml-2 px-0.5 flex jc-center align-center rounded-full bg-red-600 text-white text-xs shadow"
                                :class="{ 'absolute unread-count-position': !isMobile }"
                                title="Notifications"
                            >
                                {{ unreadMentionsCount }}
                            </span>
                        </transition>
                    </a>

                    <!-- MOBILE NOTIFICATIONS -->
                    <ul v-if="isMobile" class="sub-dropdown notifications-list relative mobile">
                        <li v-for="mention in mentions" :key="mention.mention_id">
                            <mention-link
                                :mention="mention"
                                class="mention-link text-xs"
                                :class="{ 'muted': !isMobile && mention.read_at }"
                            ></mention-link>
                        </li>
                    </ul>

                    <!-- DESKTOP NOTIFICATIONS -->
                    <div
                        v-else
                        v-show="show_notifications_dropdown"
                        ref="notificationsDropdown"
                        class="absolute notifications-list relative notifications-position shadow"
                    >
                        <div class="sticky shadow-sm px-2.5 pt-2 pb-1.5 text-xs uppercase bg-gray-100">
                            <template v-if="mentions.length === 0">
                                No Notifications
                            </template>
                            <span v-else class="pointer text-blue-900 hover:text-blue-700" @click.prevent="openNotificationsModal">
                                All Notifications
                            </span>
                        </div>

                        <div v-for="mention in mentions" :key="mention.mention_id">
                            <mention-link
                                :mention="mention"
                                class="mention-link block text-xs"
                                :class="{ 'muted': !isMobile && mention.read_at }"
                            ></mention-link>
                        </div>
                    </div>
                </li>

                <li>
                    <a href="#" class="dropdown-toggle">{{username}}</a>
                    <ul class="sub-dropdown" style="z-index:9999">
                        <li>
                            <router-link :to="{ name: 'user-profile' }">Profile</router-link>
                        </li>
                        <li v-if="showTimesheetLink">
                            <router-link :to="{ name: 'timesheets' }">Timesheets</router-link>
                        </li>
                        <li>
                            <a href="#" @click.prevent="logout">Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <ks-modal
            ref="notificationsModal"
            title="All Notifications"
            :loading="loading_mentions"
            min-width="500px"
            max-width="500px"
        >
            <div ref="mentionsContent" :style="mentionsContentStyle">
                <ks-simple-paginator :pagination="paginator" @input="fetchMentions"></ks-simple-paginator>

                <mention-link
                    v-for="mention in all_mentions"
                    :key="mention.mention_id"
                    :mention="mention"
                    reference-class="text-xs"
                    body-class="mt-1"
                    :class="{ 'bg-gray-50': mention.read_at }"
                    class="block p-2 hover:bg-gray-100"
                    @navigate="$refs.notificationsModal.close()"
                ></mention-link>
            </div>
        </ks-modal>
    </ks-top-nav>
</template>

<script>
    // External Dependencies
    import KsTopNav from 'kickstart-ui/lib/KsTopNav';
    import { mapGetters } from 'vuex';
    import api from 'kickstart-ui/lib/api';
    import {faUserSecret} from '@fortawesome/pro-solid-svg-icons/faUserSecret';
    import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell.js';
    import {faCheckSquare} from '@fortawesome/pro-regular-svg-icons/faCheckSquare.js';

    // Mixins
    import ImpersonateMixin from '@Vault/Admin/Users/mixins/ImpersonateMixin';
    import MentionsMixin from '../UI/mixins/MentionsMixin.js';
    import UserInfoMixin from '@Vault/Users/mixins/UserInfoMixin';
    import WatchesViewport from '../UI/mixins/WatchesViewport.js';

    // Repositories
    import Timesheets from '@Vault/Timesheets/repositories/TimesheetRepository';

    // Components
    import MentionLink from '../UI/MentionLink.vue';

    export default {
        name: 'TopMenu',

        mixins: [
            ImpersonateMixin,
            MentionsMixin,
            UserInfoMixin,
            WatchesViewport,
        ],

        computed: {
            ...mapGetters([
                'username',
                'is_impersonating',
                'mentions',
                'unreadMentionsCount',
                'user_id',
                'has_task_categories',
            ]),

            showTimesheetLink() {
                if ( this.isField || this.isAdmin || this.isAccountAdmin) {
                    return this.categories.length > 0;
                }

                return false;
            },

            isMobile() {
                return ['xs', 'sm'].includes(this.screenWidth);
            },

            mentionsContentStyle() {
                return {
                    minHeight: this.mentions_content_height,
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'auto',
                };
            },

            canViewTasks() {
                if ( !this.has_task_categories ) {
                    return false;
                }

                return this.isAdmin || this.isAccountAdmin;
            },

            activeLinkClasses() {
                return (name) => {
                    return {
                        'bg-blue-700': this.$route.name === name,
                        'text-white': this.$route.name === name
                    }
                }
            }
        },

        data() {
            return {
                userIcon: faUserSecret,
                notification_icon: faBell,
                task_icon: faCheckSquare,

                categories: [],

                loading_mentions: false,
                all_mentions: [],
                paginator: {},
                mentions_content_height: 0,
                show_notifications_dropdown: false,
            }
        },

        beforeMount() {
            Timesheets.get('categories', {enabled: 1})
                .then(({data}) => this.categories = data);
        },

        mounted() {
            document.addEventListener('click', this.handleClick);
        },

        beforeDestroy() {
            document.removeEventListener('click', this.handleClick);
        },

        methods: {
            handleClick(event) {
                if ( !this.$refs.notificationsDropdown?.contains(event.target) ) {
                    this.show_notifications_dropdown = false;
                }
            },

            logout() {
                api.post('/logout')
                    .then(({data}) => {
                        window.location.href = '/';
                    })
                    .catch(({response}) => {
                        console.error('DA:ERR: ', response);
                    })
            },

            clickNotifications() {
                if ( this.isMobile ) {
                    this.openNotificationsModal();
                } else {
                    this.show_notifications_dropdown = !this.show_notifications_dropdown;
                }
            },

            openNotificationsModal() {
                this.$refs.notificationsModal.open();

                this.fetchMentions();

                this.show_notifications_dropdown = false;
            },

            fetchMentions(page = 1) {
                this.loadMentions('user', this.user_id, { page })
                    .then(({data, paginator}) => {
                        this.all_mentions = data;
                        this.paginator = paginator;

                        this.$nextTick(() => {
                            const height = this.$refs.mentionsContent?.clientHeight;
                            if ( height > parseInt(this.mentions_content_height) ) {
                                this.mentions_content_height = height + 'px';
                            }
                        });
                    });
            },
        },

        components: {
            MentionLink,
            KsTopNav
        }
    }
</script>

<style>
    .notifications-list {
        max-height: 18rem;
        overflow-y: auto;
    }
    .notifications-list.mobile {
        max-height: 13rem;
    }
    .notifications-position {
        top: 60px;
        right: 0;
        width: 24rem !important;
    }
    .notifications-dropdown-toggle {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
    .unread-count {
        min-width: 1rem;
        min-height: 1rem;
    }
    .unread-count-position {
        right: 0.125rem;
        top: 0.875rem;
    }
</style>
