import Repository from 'Repository';

export class MentionsRepository extends Repository {
    constructor() {
        super();

        this.resource = ':mentionable_type/:mentionable_id/mentions';
    }
}

const Mentions = new MentionsRepository;

export default Mentions;

/**
 * @typedef Mention
 * @type {object}
 * @property {number} mention_id
 * @property {string} mentionable_type
 * @property {number} mentionable_id
 * @property {?string} reference_type
 * @property {?number} reference_id
 * @property {?string} preview
 * @property {string|object|null} url
 * @property {?string} read_at
 * @property {?number} unread_mentions_count
 */
