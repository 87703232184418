import Mentions from '../../Users/repositories/MentionsRepository.js';

export default {
    data() {
        return {
            loading_mentions: false,
        };
    },

    methods: {
        loadMentions(mentionable_type, mentionable_id, params = {}) {
            this.loading_mentions = true;

            return Mentions.params({ mentionable_type, mentionable_id })
                .list(params)
                .finally(() => {
                    this.loading_mentions = false;
                });
        }
    }
}
