<template>
    <div v-if="show">
        <div v-if="contentOnly">
            <router-view></router-view>
        </div>

        <template v-else>
            <top-menu></top-menu>
            <nav-menu></nav-menu>

            <div class="content">
                <router-view></router-view>
            </div>

            <impersonate-warning-modal ref="warningModal"></impersonate-warning-modal>

            <div id="app-footer" class="sm-12">
                <div class="t-center ">
                    {{ appName }} &copy; {{ appDate }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    // External Dependencies
    import {mapActions, mapGetters} from 'vuex';

    // Internal Dependencies
    import { routeIsAuthorized } from './helpers/permissions';
    import NavMenu from './Vault/Navigation/NavMenu.vue';
    import TopMenu from './Vault/Navigation/TopMenu.vue';
    import EventHandler from './helpers/EventHandler';
    import IntegrationRepository from './Vault/Admin/DataIntegrations/repositories/IntegrationRepository';
    import UsersRepository from './Vault/Users/repositories/UsersRepository';
    import MentionsMixin from './Vault/UI/mixins/MentionsMixin.js';
    import StopImpersonationMixin from '@/mixins/StopImpersonationMixin';
    import api from 'kickstart-ui/lib/api';
    import {hasClass, removeClass} from '@Vault/Diagrams/diagram-helpers';

    export default {
        name: 'App',

        mixins: [
            MentionsMixin,
            StopImpersonationMixin,
        ],

        props: {
            entityId: {},
            userId: {},
            userRoleId: {},
            username: {},
            userDisplay: {},
            userType: {},
            channels: {
                required: true,
                type: Object
            },
            perms: {},
            userRole: {},
            systemAdmin: {
                default: false,
            },
            isImpersonating: {
                default: false,
            },
            appVersion: {
                type: String,
                default: import.meta.env.VITE_APP_VERSION,
            },
            appName: {
                type: String,
                default: import.meta.env.VITE_APP_NAME,
            },
            appDate: {
                type: String,
            },
            hasTaskCategories: {
                default: false,
            },
        },

        computed: {
            ...mapGetters([
                'permissions',
                'app_version',
                'refresh_required',
                'is_impersonating',
            ]),

            contentOnly() {
                return this.$route?.meta?.popup ?? false;
            },
        },

        data() {
            return {
                show: false,
            }
        },

        created() {
            this.setEntityId(this.entityId);
            this.setUserInfo({
                user_id: this.userId,
                username: this.username,
                user_role_id: this.userRoleId,
                user_display: this.userDisplay,
                user_type: this.userType,
            });

            this.setPermissions(this.perms);
            this.checkPermissions();
            this.setUserRole(this.userRole);
            this.setSystemAdmin(this.systemAdmin);
            this.setIsImpersonating(this.isImpersonating);
            this.setAppVersion(this.appVersion);
            this.setHasTaskCategories(this.hasTaskCategories);
            this.setupEventChannels();
        },

        mounted() {
            this.setAppLoaded(true);
            this.fetchDataIntegrationOptions();

            EventHandler.channel('entity')
                .listen('refresh-integration-options', () => {
                    this.fetchDataIntegrationOptions();
                });

            EventHandler.channel('user')
                .listen('system-data-change', (data) => {
                    Object.values(data.types || []).forEach((type) => {
                        if (type == 'permission' || type == 'user_role') {
                            this.fetchUserAccessData();
                        }
                    });
                });

            EventHandler.channel('user')
                .listen('deactivated', (data) => {
                    if ( data.user_status === 'deactivated' ) {
                        this.$toast({
                            type: 'error',
                            message: 'Your account has been deactivated and you will be logged out momentarily'
                        });
                        setTimeout(() => {
                            api.post('/logout')
                                .then(({data}) => {
                                    window.location.href = '/';
                                })
                                .catch(({response}) => {
                                    console.error('DA:ERR: ', response);
                                })
                        }, 3000);
                    }
                })
                .listen('user_mentioned', (mention) => {
                    this.updateMention(mention);
                })
                .listen('user_mention_deleted', (mention) => {
                    this.deleteMention(mention);
                });

            this.loadEntityConfigs(this.entityId);
            this.impersonateListener();

            this.mobileMenuEvents();

            this.loadMentions('user', this.userId)
                .then(({data}) => {
                    this.setMentions(data);
                });
        },

        beforeDestroy() {
            this.mobileMenuEvents(true);
        },

        methods: {
            ...mapActions([
                'setEntityId',
                'setUserInfo',
                'setUserId',
                'setUserRoleId',
                'setUsername',
                'setAppLoaded',
                'setPermissions',
                'setUserRole',
                'setIntegrationOptions',
                'setUserType',
                'setSystemAdmin',
                'setIsImpersonating',
                'setRefreshRequired',
                'setAppVersion',
                'setHasTaskCategories',
                'setS3Configs',
                'setMentions',
                'updateMention',
                'deleteMention',
            ]),

            ...mapActions('config', [
                'loadEntityConfigs'
            ]),

            setupEventChannels() {
                for (let label in this.channels) {
                    // Create the channel.
                    EventHandler.addChannel(label, this.channels[label]);

                    // Each channel has 3 default events we listen on.
                    let channel = EventHandler.channel(label);

                    channel.listen('general-error', (message) => {
                        this.$toast({
                            type: 'error',
                            message: message.message || 'An error occurred',
                            duration: message.duration || 0,
                        });
                    }).listen('general-warning', (message) => {
                        if ( message.hasOwnProperty('message') ) {
                            this.$toast({
                                type: 'warning',
                                message: message.message,
                                duration: message.duration || 0,
                            });
                        }
                    }).listen('general-notice', (message) => {
                        if ( message.hasOwnProperty('message') ) {
                            this.$toast({
                                type: 'info',
                                message: message.message,
                                duration: message.duration || 0,
                            });
                        }
                    });
                }
            },

            checkPermissions() {
                this.$nextTick(() => {
                    if ( !routeIsAuthorized(this.$route) ) {
                        this.$router.replace({name: 'not-authorized'});
                    }

                    this.show = true;
                });
            },

            fetchDataIntegrationOptions() {
                IntegrationRepository.get('options')
                    .then(({data}) => {

                        for (let i in data) {
                            let integration = data[i];
                            let integration_options = {};
                            integration_options.integration_code = integration.integration_code;
                            integration_options.options = integration.options;

                            this.setIntegrationOptions(integration_options);
                        }

                    })
                    .catch(() => {
                        console.error('Failed to fetch data integration options.')
                    });
            },

            transformPermissionsObjectToArray(permissions) {
                return Object.fromEntries(Object.values(permissions || []).map((permission) => {
                    return [permission.permission_code, permission.permission_id];
                }));
            },

            fetchUserAccessData() {
                UsersRepository.find(this.userId, {with: 'permissions'})
                    .then(({data}) => {
                        this.setUserRole(data.role.role_name);
                        this.setUserType(data.role.role_type);
                        this.setPermissions(this.transformPermissionsObjectToArray(data.permissions));
                        this.setRefreshRequired();
                        this.$toast({
                            message: 'System data has changed. You may need to reload the app.',
                            type: 'warning'
                        });
                    })
                    .catch(() => {
                        this.$toast({
                            message: 'Unable to update system data. Please reload the app.',
                            type: 'error'
                        });
                    });
            },

            mobileMenuEvents(remove = false) {
                const top_nav_link = document.querySelector('.mobile-menu.has-sidebar');
                const side_nav_link = document.querySelector('.mobile-menu');
                const side_nav = document.querySelector('.side-nav');

                const handleTopNavClick = () => {
                    if ( hasClass(side_nav, 'show') ) {
                        removeClass(side_nav, 'show');
                        removeClass(document.body, 'side-nav-open');
                    }
                }
                const handleSideNavClick = () => {
                    if ( hasClass(top_nav_link.parentNode, 'show') ) {
                        removeClass(top_nav_link.parentNode, 'show');
                    }
                }

                if ( remove ) {
                    top_nav_link?.removeEventListener('click', handleTopNavClick);
                    side_nav_link?.removeEventListener('click', handleSideNavClick);
                } else {
                    top_nav_link?.addEventListener('click', handleTopNavClick);
                    side_nav_link?.addEventListener('click', handleSideNavClick);
                }
            },
        },

        watch: {
            permissions() {
                this.checkPermissions();
            },
        },

        components: {
            NavMenu,
            TopMenu,
        }
    }
</script>
