import './bootstrap.js';

// External Dependencies
import Vue from 'vue';

// Internal Dependencies
import store from './store';
import router from './routes';
import App from './App.vue';
import addRouterHooks from './routes/router-hooks';
import { refresh } from './routes';

import VueVirtualScroller from 'vue-virtual-scroller';
import '~vue-virtual-scroller/dist/vue-virtual-scroller.css';

Vue.use(VueVirtualScroller);

// Enable devtools in development builds
if ( import.meta.env.MODE === 'development' ) {
    Vue.config.devtools = true;
}

const app = new Vue({
    store,
    router,
    data() {
        return {
            environment: import.meta.env.VITE_APP_ENV
        }
    },
    components: {
        App
    }
});

addRouterHooks(router, store, 'app');

router.onReady(() => {
    app.$mount('#app');
});

// Handle router and preload errors
// PROD: Immediately refresh the page to the URL the user last clicked on
// DEV: Refresh the page to URL last clicked after 10 seconds
const appUpdated = (event) => {
    try {
        event?.preventDefault();
    } catch ( error ) {
        //
    }

    if ( typeof event !== 'undefined' ) {
        console.error(event);
    }

    if ( import.meta.env.DEV ) {
        setTimeout(() => {
            refresh(app.$store.state.route_to);
        }, 10000);
    } else {
        refresh(app.$store.state.route_to);
    }
};

router.onError(appUpdated);
window.addEventListener('vite:preloadError', appUpdated);
